import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCart } from '../Context/CartContext';
import '../Styles/Cart.css'
import axios from 'axios'; // If you are using axios for API calls
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import useDraggableScroll from '../Components/useDraggableScroll';
import GoToTop from '../Components/GoToTop';

const Cart = () => {
  const { cartItems, removeFromCart } = useCart();
  const navigate = useNavigate();
  const [promoCode, setPromoCode] = useState('');
  const [recommendedItems, setRecommendedItems] = useState([]);
  const { addToCart, showItemAddedPopup, handleQuantityChange } = useCart(); // Make sure you have the useCart hook
  const { onMouseDown, onMouseMove, onMouseUp, isDragging } = useDraggableScroll();
  const dispensaryName = cartItems.length > 0 ? cartItems[0].dispensaryName : "No dispensary";
  const dispensaryId = cartItems.length > 0 ? cartItems[0].dispensaryId : "No items to recommend";
  console.log(dispensaryName)
  
  // setting up total fees
  const deliveryFee = 0.00;
  const subtotal = cartItems.reduce((total, item) => total + (item.price * item.quantity), 0);
  const taxesAndFees = (subtotal + deliveryFee) * 0.25;
  const total = subtotal + deliveryFee + taxesAndFees;

  const handlePopUp = () => {
    navigate('/cart');
  };

  const fetchDispensaryMenu = async (dispensaryId) => {
    try {
      const response = await axios.get(`https://toke-api-ye64.onrender.com/dispensaries/${dispensaryId}`);
      const dispensaryData = response.data;
      // Example: Extracting all items from the first category
      const firstCategoryItems = dispensaryData.menu[0]?.items || [];
      setRecommendedItems(firstCategoryItems);
    } catch (error) {
      console.error('Error fetching dispensary menu:', error);
    }
  };

  useEffect(() => {
    localStorage.setItem('cartItems', JSON.stringify(cartItems));
  }, [cartItems]);

  useEffect(() => {
    fetchDispensaryMenu(dispensaryId);
  }, [dispensaryId]);

  const handleContinuePress = () => {
    navigate('/checkout', {
      state: {
        subtotal,
        deliveryFee,
        taxesAndFees,
        total
      }
    });
  };

  const handleAddToCart = (product, event) => {
    event.stopPropagation(); // This stops the event from bubbling up
    const productToAdd = { ...product, quantity: 1 }; // Default quantity set to 1
    console.log("Adding to cart:", productToAdd.id); 
    addToCart(productToAdd);
  };

  const handleProductSelect = (product) => {
    navigate('/product', { state: { product } });
  };

  const handleApplyPromoCode = () => {
    // Implement promo code application logic here
    alert('Promo code applied!'); // Placeholder action
  };

  return (
    <div className="cart-container">
      {showItemAddedPopup && (
        <div
          aria-live="polite"
          style={{
            fontWeight: 'bold',
            fontSize: '13px',
            position: 'fixed',
            top: '5%',
            right: '5%',
            backgroundColor: 'white',
            color: '#101522',
            padding: '15px',
            borderRadius: '0px',
            boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)',
            zIndex: '9999999',
            opacity: '0',
            transform: 'translateY(-20px)',
            transition: 'opacity 0.2s ease, transform 0.2s ease',
            opacity: '1',
            transform: 'translateY(0)',
            width: '300px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '0 auto'
          }}
        > Added to cart!
          <p>
            <button
              style={{
                color: 'white', 
                backgroundColor: '#101522', 
                borderRadius: '0px', 
                padding: '10px 20px',
                transition: 'background-color 0.3s ease',
                border: 'none'
              }}
              onClick={handlePopUp}
              onMouseOver={(e) => e.target.style.backgroundColor = 'gray'} // Hover effect
              onMouseOut={(e) => e.target.style.backgroundColor = '#101522'} // Reset on mouse out
            >
              View Cart
            </button>
          </p>
        </div>
      )}

      <h2 className="cart-header">My Cart</h2>
      {cartItems.length === 0 ? (
        <div className="empty-cart">
          <p>Your cart is empty!</p>
          <button onClick={() => navigate('/shops')} className="shop-now-button">Shop Now</button>
        </div>
      ) : (
        <>
          {cartItems.length > 0 && (
            <p className="dispensary-name">From: {dispensaryName}</p>
          )}
          {cartItems.map((item) => (
            <div key={item.id} className="item-container">
              <div className="item-details">
                <img src={item.imageUrl} alt={item.name} className="item-image" />
                <div className="item-text">
                  <p className="item-name">{item.name}</p>
                  <div className="quantity-controls">
                    <button className='adjust-quantity-button' onClick={() => handleQuantityChange(item.id, item.quantity -= 1)}>-</button>
                    <p className='product-quantity'>{item.quantity}</p>
                    <button className='adjust-quantity-button' onClick={() => handleQuantityChange(item.id, item.quantity += 1)}>+</button>
                  </div>
                  <p>Price: ${item.price}</p>
                </div>
              </div>
              <button onClick={() => removeFromCart(item._id)} className="remove-button">Remove</button>
            </div>
          ))}

          {cartItems.length > 0 && (
            <>
              <h3 className="section-header">Add to Order</h3>
              <div className="product-container"
                style={{ display: 'flex', overflowX: 'auto', cursor: isDragging ? 'grabbing' : 'grab' }}
                onMouseDown={onMouseDown}
                onMouseMove={onMouseMove}
                onMouseLeave={onMouseUp}
                onMouseUp={onMouseUp}> 
                {recommendedItems.map(filteredProduct => (
                  <div key={filteredProduct.id} onClick={() => handleProductSelect(filteredProduct)} className="product-card" style={{ flex: '0 0 auto', margin: '0 10px' }}>
                    <img src={filteredProduct.imageUrl} alt={filteredProduct.name} className="product-image" />
                    <p className="product-name">{filteredProduct.name}</p>
                    <p className="product-thc">{filteredProduct.thcPercentage}% THC</p>
                    <p className="product-price">${filteredProduct.price}</p>
                    <p className="product-price">{filteredProduct.dispensaryName}</p>
                    <button onClick={(e) => {
                      e.stopPropagation(); // Stop event from bubbling up
                      handleAddToCart(filteredProduct, e);
                    }} className="add-to-cart-button">
                      <FontAwesomeIcon icon={faShoppingCart} />
                    </button>
                  </div>
                ))}
              </div>
            </>
          )}

          {cartItems.length > 0 && (
            <>
              <h3 className="section-header">Order Summary</h3>
              <div className="promo-code-section">
                <input
                  type="text"
                  value={promoCode}
                  onChange={(e) => setPromoCode(e.target.value)}
                  placeholder="Promo code"
                  className="promo-input"
                />
                <button onClick={handleApplyPromoCode} className="promo-button">Apply</button>
              </div>
              <p>Subtotal: ${subtotal.toFixed(2)}</p>
              <p>Delivery Charge: ${deliveryFee.toFixed(2)}</p>
              <p>Taxes & Fees: ${taxesAndFees.toFixed(2)}</p>
              <p>Total: ${total.toFixed(2)}</p>
              <button onClick={handleContinuePress} className="continue-button">Continue</button>
            </>
          )}
        </>
      )}
      <GoToTop />
    </div>
  );
};

export default Cart;
