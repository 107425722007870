import React, { useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { OrderContext } from '../Context/OrderContext';
import '../Styles/OrderConfirmation.css'; // Ensure this path is correct

const OrderConfirmation = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { orderDetails } = location.state || {};
    const { setOrderId } = useContext(OrderContext);
    const orderId = orderDetails?.orderId || location.pathname.split('/').pop();

    console.log('OrderConfirmation orderId:', orderId);

    const handleTrackOrder = () => {
        console.log('Navigating to track order with order ID:', orderId);
        setOrderId(orderId); // Set the orderId in the context
        navigate('/track-order');
    };

    return (
        <div className="order-confirmation-container">
            <h1>Order Confirmation</h1>
            <p className="confirmation-message">Your order has been placed successfully!</p>
            <div className="order-details">
                <h2>Order Details</h2>
                <p><strong>Order ID:</strong> {orderId}</p>
                <p><strong>Delivery Address:</strong> {orderDetails?.address}</p>
                <p><strong>Phone Number:</strong> {orderDetails?.phoneNumber}</p>
                <h3>Items:</h3>
                <ul className="items-list">
                    {orderDetails?.items?.map((item, index) => (
                        <li key={index} className="item">
                            <img src={item.imageUrl} alt={item.name} className="item-image" />
                            <div className="item-info">
                                <p><strong>{item.name}</strong></p>
                                <p>Quantity: {item.quantity}</p>
                                <p>Price: ${item.price.toFixed(2)}</p>
                            </div>
                        </li>
                    ))}
                </ul>
                <h3>Order Summary:</h3>
                <p><strong>Subtotal:</strong> ${orderDetails?.subtotal.toFixed(2)}</p>
                <p><strong>Service Fee:</strong> ${orderDetails?.deliveryFee.toFixed(2)}</p>
                <p><strong>Tax:</strong> ${orderDetails?.taxesAndFees.toFixed(2)}</p>
                <h4><strong>Total:</strong> ${orderDetails?.total.toFixed(2)}</h4>
            </div>
            <button onClick={handleTrackOrder} className="track-order-button">Track Order</button>
        </div>
    );
};

export default OrderConfirmation;
