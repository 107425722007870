import React from 'react';
import BottomNavigator from './BottomNavigator';
import Footer from '../Components/Footer';
import '../Styles/AppNavigator.css'; // Assuming you'll create this CSS file

function AppNavigator() {
  return (
    <div className="app-container">
      <BottomNavigator />
      <div className="content">
        {/* Other components or routes go here */}
      </div>
      <Footer />
    </div>
  );
}

export default AppNavigator;
