import React from 'react';
import '../Styles/NumericStepper.css'

const NumericStepper = ({ quantity, setQuantity }) => {

  const handleQuantityChange = (newQuantity) => {
    setQuantity(newQuantity);
  };

  return (
    <div className='stepper-container'>
      <button
        onClick={() => handleQuantityChange(Math.max(1, quantity - 1))}
        className='stepper-btn'
      >
        <span className='stepper-btn-text'>-</span>
      </button>
      <span className='qty-text'>{quantity}</span>
      <button
        onClick={() => handleQuantityChange(quantity + 1)}
        className='stepper-btn'
      >
        <span className='stepper-btn-text'>+</span>
      </button>
    </div>
  );
};

export default NumericStepper;
