import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, signOut } from 'firebase/auth';
import '../Styles/Profile.css'; // Ensure this path is correct
import GoToTop from '../Components/GoToTop';

const Profile = () => {
  const [userName, setUserName] = useState('');
  const auth = getAuth();
  const navigate = useNavigate();
  const user = auth.currentUser;

  useEffect(() => {
    if (user) {
      setUserName(user.displayName || 'User');
    }
  }, [user]);

  const handleLogout = () => {
    signOut(auth).then(() => {
      console.log('User logged out!');
      navigate('/signin'); // Navigate to login or home page
    }).catch((error) => {
      console.error('Logout error', error);
    });
  };

  return (
    <div className="profile-container">
      <h2 className="user-name">{userName}</h2>

      <div className="profile-section">
        <h3 className="section-title">Account</h3>
        {/* <p className="profile-item" onClick={() => navigate('/Tokepass')}>Get $0 deliveries with TokePass</p> */}
        <p className="profile-item" onClick={() => navigate('/shops')}>Saved Stores</p>
        <p className="profile-item" onClick={() => navigate('/order-history')}>Order History</p>
        <p className="profile-item" onClick={() => navigate('/contact')}>Get Help</p>
        <p className="profile-item" onClick={() => navigate('/contact')}>Refer friends, get $20</p>
      </div>

      <div className="profile-section">
        <h3 className="section-title">Account Settings</h3>
        <p className="profile-item" onClick={() => navigate('/profile')}>Manage Account</p>
        <p className="profile-item" onClick={() => navigate('/payment')}>Payment</p>
        <p className="profile-item" onClick={() => navigate('/address')}>Address</p>
        <p className="profile-item" onClick={() => navigate('/phone-number')}>Phone Number</p>
        {/* <p className="profile-item" onClick={() => navigate('/privacy')}>Privacy</p>
        <p className="profile-item" onClick={() => navigate('/notifications')}>Notifications</p> */}
      </div>

      <div className="profile-section">
        <h3 className="section-title">Other</h3>
        <p className="profile-item" onClick={() => navigate('/contact')}>Become a Runner</p>
        <p className="profile-item" onClick={() => navigate('/contact')}>Become a TokePartner</p>
        <p className="profile-item" onClick={() => navigate('/legal')}>Legal</p>
      </div>

      <div className="logout-button">
        <button onClick={handleLogout}>Log Out</button>
      </div>
      <GoToTop />
    </div>
  );
};

export default Profile;
