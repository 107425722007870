import React from 'react';
import '../Styles/AgeVerificationModal.css'

const AgeVerificationModal = ({ onVerify }) => {
  const handleYesClick = () => {
    onVerify(true);
  };

  const handleNoClick = () => {
    onVerify(false);
  };

  return (
    <div className="age-verification-modal">
      <div className="modal-content">
        <h2 className="modal-title">Are you 21 years or older?</h2>
        <button className="modal-button" onClick={handleYesClick}>Yes</button>
        <button className="modal-button" onClick={handleNoClick}>No</button>
      </div>
    </div>
  )
}

export default AgeVerificationModal;
