import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { getAuth } from 'firebase/auth';
import '../Styles/Address.css';
import GoToTop from '../Components/GoToTop';

const Address = () => {
  const [address, setAddress] = useState('');
  const [editMode, setEditMode] = useState(false);
  const auth = getAuth();
  const [userName, setUserName] = useState('');
  const user = auth.currentUser;
  const addressInputRef = useRef(null);

  useEffect(() => {
    const fetchAddress = async () => {
      if (user) {
        try {
          const response = await axios.get(`https://toke-api-ye64.onrender.com/addresses/${user.uid}`);
          if (response.data.address) {
            setAddress(response.data.address);
            setEditMode(true);
          } else {
            setEditMode(false);
          }
        } catch (error) {
          console.error('Error fetching address:', error);
          alert('Error: Failed to fetch address');
        }
      }
    };

    const initializeAutocomplete = () => {
      if (addressInputRef.current && window.google) {
        const autocomplete = new window.google.maps.places.Autocomplete(addressInputRef.current);
        autocomplete.addListener('place_changed', () => {
          const place = autocomplete.getPlace();
          if (place.formatted_address) {
            setAddress(place.formatted_address);
          }
        });
      }
    };

    fetchAddress();
    if (window.google) {
      initializeAutocomplete();
    } else {
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyCqU-88n3r2P341oK4s2YAsT0SAFT5W4u0&libraries=places`;
      script.onload = () => initializeAutocomplete();
      document.head.appendChild(script);
    }
  }, [user]);

  useEffect(() => {
    if (user) {
      setUserName(user.displayName);
    } else {
      setUserName('to Toke');
    }
  }, [user]);

  const handleSave = async () => {
    const userId = user.uid;
    try {
      if (editMode) {
        await axios.put(`https://toke-api-ye64.onrender.com/addresses/${user.uid}`, { address });
        alert('Success: Address updated successfully');
      } else {
        await axios.post('https://toke-api-ye64.onrender.com/addresses', { userId, address, fullName: userName });
        alert('Success: Address added successfully');
        setEditMode(true);
      }
    } catch (error) {
      console.error('Error saving address:', error);
      alert('Error: Failed to save address');
    }
  };

  return (
    <div className="address-container">
      <h2 className="address-header">{editMode ? 'Your Address' : 'Add New Address'}</h2>
      <textarea
        ref={addressInputRef}
        className="address-input"
        value={address}
        onChange={(e) => setAddress(e.target.value)}
        placeholder={!editMode ? 'Enter your new address here' : ''}
      />
      <button className="address-button" onClick={handleSave}>
        {editMode ? 'Save Address' : 'Add Address'}
      </button>
      <GoToTop />
    </div>
  );
};

export default Address;
