import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import '../Styles/Dispensaries.css'
import GoToTop from '../Components/GoToTop';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const Dispensaries = () => {
  const [dispensaries, setDispensaries] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    fetchDispensaries();
  }, []);

  const fetchDispensaries = async () => {
    try {
      const response = await axios.get('https://toke-api-ye64.onrender.com/dispensaries');
      setDispensaries(response.data);
    } catch (error) {
      console.error('Error fetching dispensaries:', error);
    }
  };

  const filteredDispensaries = dispensaries.filter(dispensary =>
    dispensary.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleDispensarySelect = (dispensary) => {
    navigate('/dispensary', { state: { dispensary: { ...dispensary, id: dispensary._id } } });
  };

  return (
    <div className="dispensaries-container">
      <h1>Shops</h1>
      <input
        className="search-input"
        placeholder="Search shops..."
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
      />
      <div className="dispensaries-grid">
        {filteredDispensaries.map((dispensary) => (
          <div className="dispensary-card" onClick={() => handleDispensarySelect(dispensary)} key={dispensary.id}>
            <LazyLoadImage
              src={dispensary.imageUrl}
              alt={dispensary.name}
              className="dispensary-image"
              effect="blur"
            />
            <div className="dispensary-info">
              <h2 className="dispensary-title">{dispensary.name}</h2>
              <p className="dispensary-rating">Rating: {dispensary.rating}</p>
              <p className="dispensary-type">{dispensary.type}</p>
              {/* Render other details as needed */}
            </div>
          </div>
        ))}
      </div>
      <GoToTop />
    </div>
  );
};

export default Dispensaries;
