import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import '../Styles/Products.css';
import { useCart } from '../Context/CartContext'; // Adjust path as needed
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import GoToTop from '../Components/GoToTop';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const Products = () => {
  const [products, setProducts] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const navigate = useNavigate();
  const { addToCart, showItemAddedPopup } = useCart(); // Make sure you have the useCart hook

  const handlePopUp = () => {
    navigate('/cart');
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  const fetchProducts = async () => {
    try {
      const response = await axios.get('https://toke-api-ye64.onrender.com/dispensaries/products');
      setProducts(response.data);
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  };

  const handleProductSelect = (product) => {
    navigate('/product', { state: { product } });
  };

  const filteredProducts = products.filter(product =>
    product.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleAddToCart = (product, event) => {
    event.stopPropagation(); // This stops the event from bubbling up
    const productToAdd = { ...product, quantity: 1 }; // Default quantity set to 1
    console.log("Adding to cart:", productToAdd.id); 
    addToCart(productToAdd);
  };

  return (
    <div className="all-products-container">
    {showItemAddedPopup && (
        <div
          aria-live="polite"
          style={{
            fontWeight: 'bold',
            fontSize: '13px',
            position: 'fixed',
            top: '5%',
            right: '5%',
            backgroundColor: 'white',
            color: '#101522',
            padding: '15px',
            borderRadius: '0px',
            boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)',
            zIndex: '9999999',
            opacity: '0',
            transform: 'translateY(-20px)',
            transition: 'opacity 0.2s ease, transform 0.2s ease',
            opacity: '1',
            transform: 'translateY(0)',
            width: '300px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '0 auto'
      }}
        > Added to cart!
      <p>
        <button
          style={{
            color: 'white', 
            backgroundColor: '#101522', 
            borderRadius: '0px', 
            padding: '10px 20px',
            transition: 'background-color 0.3s ease',
            border: 'none'
          }}
          onClick={handlePopUp}
          onMouseOver={(e) => e.target.style.backgroundColor = 'gray'} // Hover effect
          onMouseOut={(e) => e.target.style.backgroundColor = '#101522'} // Reset on mouse out
        >
          View Cart
        </button>
      </p>
    </div>
          )}

      <h1>Products</h1>
      <input 
        type="text"
        placeholder="Search products..."
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        className="search-input"
      />
      <div className="product-grid">
        {filteredProducts.map((product, index) => (
          <div key={index} className="products-card" onClick={() => handleProductSelect(product)}>
            <div className='product-info'>
              <LazyLoadImage
                src={product.imageUrl}
                alt={product.name}
                className="product-img"
                effect="blur"
              />
              <h3 class="products-title">{product.name}</h3>
              <p class="products-price">${product.price}</p>
              <p class="products-thc">{product.thcPercentage}%</p>
              <p class="products-dispo">{product.dispensaryName}</p>  
              <button onClick={(e) => {
                e.stopPropagation();
                handleAddToCart(product, e);
              }} className="add-to-cart-button">
                <FontAwesomeIcon icon={faShoppingCart} />
              </button>
            </div>
          </div>
        ))}
      </div>
      <GoToTop />
    </div>
  );
};

export default Products;
