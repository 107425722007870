import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore'; // Import Firestore

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBpv8-hVA28pt11JvKP8GXezj__JqJOOs0",
  authDomain: "toke-customer-app-frontend.firebaseapp.com",
  projectId: "toke-customer-app-frontend",
  storageBucket: "toke-customer-app-frontend.appspot.com",
  messagingSenderId: "327178113125",
  appId: "1:327178113125:web:4d1a6b2de0d3d779ee368c"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

export { auth, db, app };