import React from 'react';
import { FaInstagram, FaLinkedin } from 'react-icons/fa';
import { VscLocation } from 'react-icons/vsc';
import { BiPhone } from 'react-icons/bi';
import { MdOutlineEmail } from 'react-icons/md';
import { MdAccessTime } from "react-icons/md";
import Logo from '../assets/icon.png'; // Assuming you're using the logo
import '../Styles/Footer.css'

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-upper">
      <div className="footer-logo">
          <a href="/"> 
          <img src={Logo} alt="Toke Logo" className="logo" />
          </a> 
        </div>
        <div className="footer-links">
          <a href="/about" className="footer-link">About Us</a>
          <a href="/contact" className="footer-link">Contact</a>
          <a href="/terms" className="footer-link">Terms</a>
          <a href="/privacy" className="footer-link">Privacy</a>
        </div>

        <div className="social-media">
          <a target='_blank' href='https://www.instagram.com/Toke.app/'><FaInstagram className="icon" /></a>
        
          <a target='_blank' href='https://www.linkedin.com/company/Toke-inc/'><FaLinkedin className="icon" /></a>
          {/* <FaFacebook className="icon" /> */}
          {/* <FaTwitter className="icon" /> */}
        </div>
      </div>

      <div className="footer-lower">
        <div className="contact-info">
          <VscLocation />
          <a className='number-link' href='https://www.google.com/maps/place/Orange+County,+CA/@33.6407058,-118.1130839,9.99z/data=!4m6!3m5!1s0x80dc925c54d5f7cf:0xdea6c3618ff0d607!8m2!3d33.7174708!4d-117.8311428!16zL20vMGNiNGo?entry=ttu'>
          <p>Orange County, CA</p>
          </a>
        </div>

        <div className="contact-info">
             <a className='number-link' href='tel:+19498422889'>  
             <BiPhone />
       
          <p>(949)842-2889</p>
          </a>
        </div>
        
        <div className="contact-info">
            <a className='email-link' href='mailto:hello@tryToke.com'> 
          <MdOutlineEmail />
          <p>hello@Toke.com</p>
          </a>
        </div>

          <div className="contact-info">
            {/* <a className='email-link' href='mailto:hello@tryToke.com'>  */}
          <MdAccessTime />
          <p>9:00AM - 10:00PM Daily</p>
          {/* </a> */}
        </div>
      </div>

      <div className="footer-bottom">
        <p>Toke Technologies LLC © 2024<br /> 
        All rights reserved.
        </p>
      </div>
    </footer>
  )
}

export default Footer
