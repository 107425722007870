import React from 'react';
import '../Styles/About.css'; // Import your CSS file for styling
import GoToTop from '../Components/GoToTop';

const About = () => {
    return (
        <div className="about-container">
            <section className="about-header">
                <h1>About Toke</h1>
            </section>

            <section className="about-content">
                <h2>Our Mission</h2>
                <p>
                    At Toke, our mission is to revolutionize the way people experience cannabis.
                    We are dedicated to providing innovative solutions and exceptional products
                    that enhance the cannabis journey for enthusiasts and connoisseurs alike.
                </p>

                <h2>Our Story</h2>
                <p>
                Founded in 2023 by two best friends.  The two founders met in middle school in their hometown of Irvine, CA, and bonded over their shared interests in technology. Both went on to study computer science in college and are now building the future of regulated delivery. Toke's mission is to provide unparalleled convenience and access to cannabis products while empowering local gig-workers and businesses.
                </p>

                <h2>Our Commitment</h2>
                <p>
                    Quality, innovation, and customer satisfaction are at the core of everything we do. 
                    We are committed to delivering excellence and pushing the boundaries in the cannabis industry.
                </p>

                <h2>Contact Us</h2>
                <p>
                    Have questions or feedback? We'd love to hear from you.
                    Reach out to us <a href='/contact'>here</a>.
                </p>
            </section>
            <GoToTop />
        </div>
    );
}

export default About;