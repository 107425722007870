import React, { useState, useEffect, useRef, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import axios from "axios";
import { useCart } from '../Context/CartContext';
import '../Styles/Checkout.css';
import GoToTop from '../Components/GoToTop';
import { auth } from '../firebaseConfig';
import { OrderContext } from '../Context/OrderContext';

const validOrangeCountyZipCodes = [
  '92683', '92804', '92704', '92805', '90631', '92801', '92703', '92677', '92630', '92627', '92647', '92530',
  '92780', '92708', '92620', '92707', '92646', '92618', '92840', '92870', '92833', '92656', '92626', '92705',
  '92701', '90630', '92843', '92886', '90620', '92691', '92692', '92821', '92648', '92688', '92867', '92802',
  '92806', '92807', '92675', '92679', '92831', '92649', '92672', '92841', '92869', '90621', '92660', '92706',
  '92883', '92612', '92602', '92673', '92604', '92653', '92835', '92614', '92868', '90680', '92629', '92782',
  '92694', '92606', '90740', '92832', '92651', '92844', '90720', '92663', '92887', '92865', '92808', '92603',
  '92637', '92617', '92845', '92866', '90623', '92625', '92610', '92657', '92861', '92624', '92655', '92823',
  '92676', '92661', '92662', '92697', '92698', '92710', '92725', '92863', '92628', '92616', '92619', '92678',
  '92684', '92799', '92822', '92857', '92871', '90633', '92709', '92623', '92650', '92652', '92654', '92659',
  '92658', '92674', '92685', '92690', '92693', '92702', '92711', '92712', '92735', '92728', '92781', '92803',
  '92811', '92809', '92814', '92812', '92816', '92815', '92817', '92825', '92834', '92837', '92836', '92838',
  '92842', '92846', '92856', '92850', '92859', '92862', '92864', '92885', '92899', '90622', '90624', '90632',
  '90721', '90743', '90742', '92605', '92609', '92607', '92615'
];

const Checkout = () => {
  const [orderPlaced, setOrderPlaced] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [activeTab, setActiveTab] = useState('stripe');
  const { orderId, setOrderId } = useContext(OrderContext);
  const location = useLocation();
  const { subtotal, deliveryFee, taxesAndFees, total } = location.state || {};
  const navigate = useNavigate();
  const user = auth.currentUser;
  const [editAddress, setEditAddress] = useState(false);
  const [editPhone, setEditPhone] = useState(false);
  const [address, setAddress] = useState('');
  const [userName, setUserName] = useState('');
  const addressInputRef = useRef(null);
  const [paymentMethod, setPaymentMethod] = useState('stripe');
  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    const fetchUserData = async () => {
      if (user) {
        try {
          const addressResponse = await axios.get(`https://toke-api-ye64.onrender.com/addresses/${user.uid}`);
          if (addressResponse.data.address) {
            setAddress(addressResponse.data.address);
            setEditAddress(true);
          } else {
            setEditAddress(false);
          }

          const phoneResponse = await axios.get(`https://toke-api-ye64.onrender.com/phone-numbers/${user.uid}`);
          if (phoneResponse.data.phoneNumber) {
            setPhoneNumber(phoneResponse.data.phoneNumber);
            setEditPhone(true);
          } else {
            setEditPhone(false);
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
          alert('Error: Failed to fetch user data');
        }
      }
    };

    const initializeAutocomplete = () => {
      if (addressInputRef.current && window.google) {
        const autocomplete = new window.google.maps.places.Autocomplete(addressInputRef.current);
        autocomplete.addListener('place_changed', () => {
          const place = autocomplete.getPlace();
          if (place.formatted_address) {
            setAddress(place.formatted_address);
          }
        });
      }
    };

    fetchUserData();
    if (window.google) {
      initializeAutocomplete();
    } else {
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyCqU-88n3r2P341oK4s2YAsT0SAFT5W4u0&libraries=places`;
      script.onload = () => initializeAutocomplete();
      document.head.appendChild(script);
    }
  }, [user]);

  const { clearCart, cartItems } = useCart();
  useEffect(() => {
    if (user) {
      setUserName(user.displayName);
    } else {
      setUserName('to Toke');
    }
  }, [user]);

  const handleSave = async () => {
    const userId = user.uid;
    try {
      if (editAddress) {
        await axios.put(`https://toke-api-ye64.onrender.com/addresses/${user.uid}`, { address });
        alert('Success: Address updated successfully');
      } else {
        await axios.post('https://toke-api-ye64.onrender.com/addresses', { userId, address, fullName: userName });
        alert('Success: Address added successfully');
        setEditAddress(true);
      }
    } catch (error) {
      console.error('Error saving address:', error);
      alert('Error: Failed to save address');
    }
  };

  const handleSavePhoneNumber = async () => {
    const userId = user.uid;
    try {
      if (editPhone) {
        await axios.put(`https://toke-api-ye64.onrender.com/phone-numbers/${user.uid}`, { phoneNumber, fullName: userName });
        alert('Success: Phone number updated successfully');
      } else {
        await axios.post('https://toke-api-ye64.onrender.com/phone-numbers', { userId, phoneNumber, fullName: userName });
        alert('Success: Phone number added successfully');
        setEditPhone(true);
      }
    } catch (error) {
      console.error('Error saving phone number:', error);
      alert('Error: Failed to save phone number');
    }
  };

  const isPhoneNumberValid = (number) => {
    const pattern = /^(\d{10}|\d{3}-\d{3}-\d{4})$/;
    return pattern.test(number);
  };

  const handlePaymentMethodChange = (method) => {
    setPaymentMethod(method);
    setActiveTab(method);
  };

  const createOrder = (data, actions) => {
    return actions.order.create({
      purchase_units: [{
        amount: {
          value: total?.toFixed(2),
        },
      }],
    });
  };

  const onApprove = (data, actions) => {
    return actions.order.capture().then((details) => {
      console.log("Payment Successful:", details);
      createAndFinalizeOrder(details.id);
    });
  };

  const onError = (error) => {
    console.error("Payment Error:", error);
    alert("Payment Error: " + error.message);
  };

  const isValidOrangeCountyZipCode = (address) => {
    console.log("Validating address:", address); // Debug log
    const zipCodeMatch = address.match(/\b\d{5}\b(?!.*\b\d{5}\b)/);
    if (zipCodeMatch) {
      const zipCode = zipCodeMatch[0];
      console.log("Extracted zip code:", zipCode); // Debug log
      return validOrangeCountyZipCodes.includes(zipCode);
    }
    return false;
  };

  const handlePlaceOrderStripe = async () => {
    if (!stripe || !elements) {
      return;
    }
  
    if (!isValidOrangeCountyZipCode(address)) {
      alert("Order can only be placed within Orange County, CA.");
      return;
    }
  
    const orderItems = cartItems.map(item => ({
      productId: item._id,
      name: item.name,
      imageUrl: item.imageUrl,
      quantity: item.quantity,
      price: item.price,
      dispensaryName: item.dispensaryName,
      dispensaryAddress: item.dispensaryAddress,
    }));
  
    try {
      const totalInCents = Math.round(total * 100);
      const response = await fetch(
        "https://toke-api-ye64.onrender.com/stripe/create-payment-intent",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            total: totalInCents,
            items: orderItems,
            totalAmount: total,
            customerName: userName,
            userId: user ? user.uid : null,
          }),
        }
      );
      const responseData = await response.json();
      const { clientSecret, orderId } = responseData;
      setOrderId(orderId);
  
      const cardElement = elements.getElement(CardElement);
      const { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: cardElement,
        },
      });
  
      if (error) {
        console.error("Payment confirmation error", error);
        alert("Payment Error: " + error.message);
      } else if (paymentIntent) {
        createAndFinalizeOrder(orderId);
      }
    } catch (error) {
      console.error("Error", error);
      alert("Failed to create payment intent");
    }
  };

  const createAndFinalizeOrder = async (orderId) => {
    try {
      const userId = user ? user.uid : null;
      let latestUserAddress = "";
      let latestPhoneNumber = "";
  
      if (userId) {
        const addressResponse = await axios.get(`https://toke-api-ye64.onrender.com/addresses/${userId}`);
        latestUserAddress = addressResponse.data.address;
  
        const phoneResponse = await axios.get(`https://toke-api-ye64.onrender.com/phone-numbers/${userId}`);
        latestPhoneNumber = phoneResponse.data.phoneNumber;
      }
  
      const orderDetails = {
        subtotal,
        deliveryFee,
        taxesAndFees,
        total,
        userId,
        address: latestUserAddress,
        phoneNumber: latestPhoneNumber,
        items: cartItems.map(item => ({
          productId: item._id,
          name: item.name,
          imageUrl: item.imageUrl,
          quantity: item.quantity,
          price: item.price,
          dispensaryName: item.dispensaryName,
          dispensaryAddress: item.dispensaryAddress,
        })),
        customerName: userName
      };
  
      await axios.post("https://toke-api-ye64.onrender.com/orders/finalize-order", {
        orderId: orderId,
        orderDetails: orderDetails
      });
  
      await axios.post("https://toke-api-ye64.onrender.com/orders/available", {
        orderId: orderId,
        orderDetails: orderDetails
      });
  
      setOrderPlaced(true);
      alert("Order finalized successfully!");
      clearCart();
  
      const orderedItemsDescription = cartItems.map(item => 
        `${item.quantity} x ${item.name} from ${item.dispensaryName}`).join(', ');
  
      const smsData = {
        message: `Hi ${userName}, your Toke order for a total of $${total.toFixed(2)} has been placed successfully. Your order: ${orderedItemsDescription} will be delivered to ${latestUserAddress}. Phone: ${latestPhoneNumber}`,
        toNumbers: [latestPhoneNumber]
      };
  
      const smsResponse = await axios.post('https://toke-api-ye64.onrender.com/twilio/send-sms', smsData);
      console.log("SMS Response:", smsResponse.data);
  
      navigate(`/order-confirmation/${orderId}`, { state: { orderDetails } });
  
    } catch (error) {
      console.error("Error finalizing order:", error);
  
      if (error.isAxiosError) {
        console.error("Axios error details:", {
          status: error.response?.status,
          data: error.response?.data,
        });
      }
  
      alert("Failed to finalize the order: " + (error.response ? error.response.data : error.message));
    }
  };

  const handleTrackOrder = () => {
    navigate(`/track-order`);
  };

  return (
    <div className="checkout-container">
      <h1 className="checkout-header">Place Order</h1>
      <div className="stripe-payment">
        <CardElement className="card-element" />
      </div>
      <div className="address-container">
        <div className="address-header">{editAddress ? 'Your Address' : 'Add New Address'}</div>
        <textarea
          ref={addressInputRef}
          className="address-input"
          value={address}
          onChange={(e) => setAddress(e.target.value)}
          placeholder={!editAddress ? 'Enter your new address here' : ''}
        />
        <button className="address-button" onClick={handleSave}>
          {editAddress ? 'Save Address' : 'Add Address'}
        </button>
      </div>
      <div className="address-container">
        <div className="address-header">{editPhone ? 'Your Phone Number' : 'Add New Phone Number'}</div>
        <input
          className="address-input"
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
          placeholder={!editPhone ? 'Enter your new phone number here' : ''}
        />
        <button className="address-button" onClick={handleSavePhoneNumber}>
          {editPhone ? 'Save Phone Number' : 'Add Phone Number'}
        </button>
      </div>
      <div>
        <h3>Order Summary:</h3>
        <p className="phone-number-text">Phone Number: {phoneNumber}</p>
        <p className="address-text">Delivering to: {address}</p>
        <p>Subtotal: ${subtotal?.toFixed(2)}</p>
        <p>Service Fee: ${deliveryFee?.toFixed(2)}</p>
        <p>Tax: ${taxesAndFees?.toFixed(2)}</p>
        <h4>Total: ${total?.toFixed(2)}</h4>
      </div>
      {!orderPlaced ? (
        <button onClick={handlePlaceOrderStripe} className="place-order-button">
          Place Order
        </button>
      ) : (
        <button onClick={handleTrackOrder} className="track-order-button">
          Track Order
        </button>
      )}
      <GoToTop />
    </div>
  );
};

export default Checkout;
