import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  signInWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider
} from 'firebase/auth';
import { auth } from '../firebaseConfig'; // adjust the path as needed
import googleLogo from '../assets/google-logo.png'
import GoToTop from '../Components/GoToTop';
import '../Styles/SignIn.css'

const SignIn = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleSignIn = async () => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate('/home');
    } catch (error) {
      alert('Error signing in: ' + error.message);
    }
  };


  const handleGoogleSignIn = async () => {
    const provider = new GoogleAuthProvider();
    try {
      await signInWithPopup(auth, provider);
      navigate('/home');
    } catch (error) {
      alert('Error signing in with Google: ' + error.message);
    }
  };


  return (
    <div className='container'>
      <div className='logo-container'>
        <h1 className='toke-logo'>Toke</h1>
      </div>

      <label className='label'>Email</label>
      <input
        className='input'
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        type="email"
      />

      <label className='label'>Password</label>
      <input
        className='input'
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />

    
        <button className='signup-btn' onClick={handleSignIn}>Let's Go</button>
        <button className='signin-text' onClick={() => navigate('/signup')}>Don't have an account? Sign Up</button>
        <button className='signin-text' onClick={() => navigate('/reset-password')}>Forgot password?</button>
    
      {/* Google Sign-In button */}
      <button onClick={handleGoogleSignIn} className='google-btn'><img
                src={googleLogo} // Replace with your Google logo image path
                className='google-logo'
                alt="Sign in with Google"
            /></button>
        <GoToTop />
    </div>
    
  );
  
};
const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px',
    paddingTop: '100px',
    width: '50%',
    margin: '0 auto'
  },
  input: {
    borderBottom: '1px solid black', // Black underline
    //padding: '10px',
    //margin: '5px',
   width: '100%',
    height: '40px',
    border: 'none', // Removes the box styling
    outline: 'none', // Optional: removes the highlight border on focus
},
label: {
    fontSize: '16px',
    color: '#000',
    marginTop: '10px',
    alignSelf: 'flex-start', // Aligns label to the top left
},
  signInText: {
    background: 'none',
    border: 'none',
    marginTop: '20px',
    color: '#101522',
    cursor: 'pointer',
    fontSize: '12px',
  },
  logoContainer: {
    marginBottom: '10px',
  },
  input: {
    borderRadius: '33px',
  },
  TokeLogo: {
    width: 200,
    height: 'auto'
  },
  signUpBtn: {
    width: '200px',
    backgroundColor: '#101522',
    textAlign: 'center',
    padding: '20px',
    marginTop: 20,
    borderRadius: '0px',
    color: 'white',
    fontWeight: 'bold',
    cursor: 'pointer',
    border: 'none'
  },
  googleButton: {
    marginTop: '20px',
    border: 'none',
    cursor: 'pointer',
    background: 'none'
  },
  googleLogo: {
    background: 'none',
    width: '30px', // Adjust as needed
    height: '30px', // Adjust as needed
    cursor: 'pointer',

},

  // Add other styles as needed
};



export default SignIn;
