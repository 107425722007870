import { useRef, useEffect } from 'react'
//import Loader from 'react-loaders'
import emailjs from '@emailjs/browser'
import '../Styles/Contact.css'
import GoToTop from '../Components/GoToTop'

const Contact = () => {
    const refForm = useRef()

      const sendEmail = (e) => {
        e.preventDefault()

        emailjs
            .sendForm(
                'service_zyohmvs',
                'template_pi4z91v',
                refForm.current,
                '7vT6EdAOjiFPfpm9G'
            )
            .then(
                () => {
                    alert('Message sent!')
                    window.location.reload(false)
                },
                () => {
                    alert('Failed to send, please try again.')
                }
            )
    }

  return (
    <>
        <div className='container'>
            <div className='text-zone'>
                <h1 className='title'>
                    Contact Us
                </h1>
                <p className='sub-title'>
                    Please feel free to reach out with any questions or feedback!  
                </p>
                <div className='contact-form'>
                    <form ref={refForm} onSubmit={sendEmail}>
                        <ul className='input-container'>
                            <li className='item'>
                                <input 
                                    className='input'
                                    type='text' 
                                    name='name' 
                                    placeholder='Name'
                                    required 
                                />
                            </li>
                            <li className='item'>
                                <input 
                                    className='input'
                                    type='email'
                                    name='email' 
                                    placeholder='Email' 
                                    required 
                                />
                            </li>
                            <li>
                                <input 
                                    className='input'
                                    placeholder="subject" 
                                    type='text' 
                                    name='subject' 
                                    required 
                                /> 
                            </li>
                            <li>
                                <textarea 
                            
                                    placeholder='Message' 
                                    name='message' 
                                    required
                                ></textarea>
                            </li>
                            <li>
                                <input type='submit' className='flat-button' value='SEND'/>
                            </li>
                        </ul>
                    </form>
                </div>
            </div>
        </div>
        <GoToTop />
    </>
  )
}

export default Contact