import './App.css';
import './assets/fonts/Poppins-Regular.ttf';
import './assets/fonts/Poppins-Medium.ttf';
import './assets/fonts/Poppins-Bold.ttf';
import './assets/fonts/Poppins-SemiBold.ttf';
import './assets/fonts/Poppins-Light.ttf';

import React, { useState, useEffect } from 'react';
import { OrderProvider } from './Context/OrderContext';
import ReactGA from 'react-ga';
import { BrowserRouter as Router } from 'react-router-dom';
import RootNavigator from './Routes/RootNavigator';
import { CartProvider } from './Context/CartContext';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { LoadScript } from '@react-google-maps/api';
import AgeVerificationModal from './Components/AgeVerificationModal';

const stripePromise = loadStripe('pk_test_51Oy3erBgrIT7cTKLIO8LZspD9g9OuNE5zw2g2Z5WaCx0Yzvi3NmHrlZ87FHsVZpfcTaZ0hQwL4yJH7UFCcJK4uW9008mM69XZP');

function App() {
  const [ageVerified, setAgeVerified] = useState(false);

  useEffect(() => {
    ReactGA.initialize('G-9C8130C1YL');
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  useEffect(() => {
    const isAgeVerified = localStorage.getItem('ageVerified') === 'true';
    setAgeVerified(isAgeVerified);
  }, []);

  const handleAgeVerification = (isVerified) => {
    localStorage.setItem('ageVerified', isVerified);
    setAgeVerified(isVerified);
  };

  return (
    <LoadScript googleMapsApiKey="AIzaSyCqU-88n3r2P341oK4s2YAsT0SAFT5W4u0" libraries={["places"]}>
      <Elements stripe={stripePromise}>
        <OrderProvider>
          <CartProvider>
            <Router>
              <div className='app-container'>
                {!ageVerified && <AgeVerificationModal onVerify={handleAgeVerification} />}
                <RootNavigator />
              </div>
            </Router>
          </CartProvider>
        </OrderProvider>
      </Elements>
    </LoadScript>
  )
}

export default App;
