import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { GoogleMap, DirectionsRenderer } from '@react-google-maps/api';
import '../Styles/CustomerOrderTracking.css';
import { OrderContext } from '../Context/OrderContext';

const CustomerOrderTracking = () => {
  const { orderId } = useContext(OrderContext);
  const [orderStatus, setOrderStatus] = useState('');
  const [driverName, setDriverName] = useState('');
  const [dispensaryAddress, setDispensaryAddress] = useState('');
  const [customerAddress, setCustomerAddress] = useState('');
  const [directions, setDirections] = useState(null);

  useEffect(() => {
    const fetchOrderStatus = async () => {
      try {
        console.log('Fetching order status for order ID:', orderId);
        const response = await axios.get(`https://toke-api-ye64.onrender.com/orders/${orderId}`);
        const orderData = response.data;
        console.log('Fetched order data:', orderData);
        setOrderStatus(orderData.status);
        setDriverName(orderData.driverName);
        setDispensaryAddress(orderData.items[0].dispensaryAddress);
        setCustomerAddress(orderData.address);
      } catch (error) {
        console.error('Error fetching order status:', error);
      }
    };

    if (orderId) {
      fetchOrderStatus();
    }
  }, [orderId]);

  useEffect(() => {
    const fetchDirections = async () => {
      if (dispensaryAddress && customerAddress) {
        console.log('Fetching directions from', dispensaryAddress, 'to', customerAddress);
        const directionsService = new window.google.maps.DirectionsService();
        directionsService.route(
          {
            origin: dispensaryAddress,
            destination: customerAddress,
            travelMode: window.google.maps.TravelMode.DRIVING,
          },
          (result, status) => {
            if (status === window.google.maps.DirectionsStatus.OK) {
              console.log('Directions result:', result);
              setDirections(result);
            } else {
              console.error(`Error fetching directions: ${status}`);
            }
          }
        );
      }
    };

    fetchDirections();
  }, [dispensaryAddress, customerAddress]);

  return (
    <div className="track-order-container">
      <h1>Track Order</h1>
      <p>Order Status: {orderStatus}</p>
      {driverName && <p>Driver: {driverName}</p>}
      {dispensaryAddress && customerAddress && (
        <GoogleMap
          mapContainerStyle={{ width: '100%', height: '400px' }}
          center={{ lat: 33.6846, lng: -117.8265 }}
          zoom={12}
        >
          {directions && <DirectionsRenderer directions={directions} />}
        </GoogleMap>
      )}
    </div>
  );
};

export default CustomerOrderTracking;
