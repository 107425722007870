import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { createUserWithEmailAndPassword, GoogleAuthProvider, signInWithPopup, updateProfile } from 'firebase/auth';
import axios from 'axios';
import { auth } from '../firebaseConfig';
import googleLogo from '../assets/google-logo.png'
import GoToTop from '../Components/GoToTop';
import '../Styles/SignIn.css'

const SignUp = () => {
    const navigate = useNavigate();
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [address, setAddress] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const addressInputRef = useRef(null);

    useEffect(() => {
        const loadGoogleMapsScript = () => {
            if (!window.google) {
                const script = document.createElement('script');
                script.type = 'text/javascript';
                script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyCqU-88n3r2P341oK4s2YAsT0SAFT5W4u0&libraries=places`;
                document.head.appendChild(script);
                script.addEventListener('load', () => initializeAutocomplete(addressInputRef.current));
            } else {
                initializeAutocomplete(addressInputRef.current);
            }
        };

        const initializeAutocomplete = (input) => {
            if (input && window.google) {
                const autocomplete = new window.google.maps.places.Autocomplete(input);
                autocomplete.addListener('place_changed', () => {
                    const place = autocomplete.getPlace();
                    if (place.formatted_address) {
                        setAddress(place.formatted_address);
                    }
                });
            }
        };

        loadGoogleMapsScript();
    }, []);

    const isPhoneNumberValid = (number) => {
        const pattern = /^(\d{10}|\d{3}-\d{3}-\d{4})$/;
        return pattern.test(number);
      };
      

    const handleGoogleSignIn = () => {
        const provider = new GoogleAuthProvider();
        signInWithPopup(auth, provider)
            .then((result) => {
                navigate('/home');
            })
            .catch((error) => {
                alert('Error during Google Sign In: ' + error.message);
            });
    };

    const handleEmailSignUp = async () => {
        if (password !== confirmPassword) {
            alert('Passwords do not match');
            return;
        }
        if (!isPhoneNumberValid(phoneNumber)) {
            alert('Invalid phone number format');
            return;
        }
        try {
            const userCredential = await createUserWithEmailAndPassword(auth, email, password);
            const userId = userCredential.user.uid;
    
            const fullName = `${firstName} ${lastName}`;
            await updateProfile(userCredential.user, {
                displayName: fullName,
            });
    
            // Post address to the backend
            await axios.post('https://toke-api-ye64.onrender.com/addresses', { userId, address, fullName })
                .catch((error) => {
                    throw new Error(`Error saving address: ${error.message}`);
                });
    
            // Post phone number to the backend
            await axios.post('https://toke-api-ye64.onrender.com/phone-numbers', { userId, phoneNumber, fullName })
                .catch((error) => {
                    throw new Error(`Error saving phone number: ${error.message}`);
                });
    
            navigate('/home');
        } catch (error) {
            alert('Sign up failed. Please try again. ' + error.message);
        }
    };

    return (
        <div className='container'>
            <div className='logo-container'>
                <h1 className='toke-logo'>Toke</h1>
            </div>

            <label className='label'>First Name</label>
            <input 
                className='input' 
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
            />

            <label className='label'>Last Name</label>
            <input 
                className='input' 
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
            />

            <label className='label'>Email</label>
            <input 
                className='input' 
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                type="email"
            />
            <label className='label'>Phone Number</label>
            <input 
                className='input' 
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                type="tel"
            />

            <label className='label'>Address</label>
            <input 
                ref={addressInputRef} 
                placeholder=''
                className='input' 
                value={address}
                onChange={(e) => setAddress(e.target.value)}
            />
            <label className='label'>Password</label>
            <input 
                placeholder=''
                className='input' 
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
            />

            <label className='label'>Confirm Password</label>
            <input 
                placeholder=''
                className='input' 
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
            />
            <p className='decree'> By creating an account with Toke, you agree to our <a href='/terms' target='_blank' >terms and conditions</a>.
      </p>

            <button className='signup-btn' onClick={handleEmailSignUp}>Let's Go</button>
            <button className='signin-text' onClick={() => navigate('/signin')}>Already have an account? Sign In</button>
            <button onClick={handleGoogleSignIn} className='google-btn'><img
                src={googleLogo} // Replace with your Google logo image path
                className='google-logo'
                alt="Sign in with Google"
            /></button>
            <GoToTop />
        </div>
    );
};

export default SignUp;