import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import '../Styles/Dispensary.css'; // Import your new CSS file here
import { useCart } from '../Context/CartContext'; // Adjust path as needed
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import GoToTop from '../Components/GoToTop';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const Dispensary = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispensary = location.state?.dispensary;
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [products, setProducts] = useState([]);
  const { addToCart, showItemAddedPopup } = useCart(); // Make sure you have the useCart hook

  const handlePopUp = () => {
    navigate('/cart');
  };

  useEffect(() => {
    if (dispensary && dispensary.id) {
      const fetchMenuItems = async () => {
        try {
          const response = await axios.get(`https://toke-api-ye64.onrender.com/dispensaries/${dispensary.id}/menu`);
          if (response.data && Array.isArray(response.data)) {
            setCategories(response.data);
            const allProducts = response.data.reduce((acc, category) => [...acc, ...category.items], []);
            setProducts(allProducts);
          } else {
            setCategories([]);
          }
        } catch (error) {
          console.error('Error fetching menu items:', error);
        }
      };
      fetchMenuItems();
    }
  }, [dispensary]);

  const handleAddToCart = (product, event) => {
    event.stopPropagation(); // This stops the event from bubbling up
     const productToAdd = { ...product, quantity: 1 }; // Default quantity set to 1
     console.log("Adding to cart:", productToAdd.id); 
     addToCart(productToAdd);
   };

  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
    setProducts(category.items);
  };

  const handleProductSelect = (product) => {
    navigate('/product', { state: { product } });
  };

  return (
    <div className="dispensary-container">

{showItemAddedPopup && (
        <div
          aria-live="polite"
          style={{
            fontWeight: 'bold',
            fontSize: '13px',
            position: 'fixed',
            top: '5%',
            right: '5%',
            backgroundColor: 'white',
            color: '#101522',
            padding: '15px',
            borderRadius: '0px',
            boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)',
            zIndex: '9999999',
            opacity: '0',
            transform: 'translateY(-20px)',
            transition: 'opacity 0.2s ease, transform 0.2s ease',
            opacity: '1',
            transform: 'translateY(0)',
            width: '300px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '0 auto'
      }}
        > Added to cart!
      <p>
        <button
          style={{
            color: 'white', 
            backgroundColor: '#101522', 
            borderRadius: '0px', 
            padding: '10px 20px',
            transition: 'background-color 0.3s ease',
            border: 'none'
          }}
          onClick={handlePopUp}
          onMouseOver={(e) => e.target.style.backgroundColor = 'gray'} // Hover effect
          onMouseOut={(e) => e.target.style.backgroundColor = '#101522'} // Reset on mouse out
        >
          View Cart
        </button>
      </p>
    </div>
          )}
      <h2 className="dispensary-title">{dispensary.name}</h2>

      <div className="category-container">
        <h3 className="subtitle">Shop by Category</h3>
        <div className="scroll-container">
          {categories.map(category => (
            <div key={category._id} onClick={() => handleCategorySelect(category)} className={`category-card ${selectedCategory === category ? 'active' : ''}`}>
              {/* <img src={category.imageUrl} alt={category.name} className="category-image" /> */}
              <p className="category-name">{category.name}</p>
            </div>
            
          ))}
        </div>
      </div>

      <div>
        <h3 className="subtitle">
          {selectedCategory ? `Products in ${selectedCategory.name}` : 'All Products'}
        </h3>
        <div className='products-container'>
          {products.map(product => (
            <div key={product._id} onClick={() => handleProductSelect(product)} className="product-card">
              <LazyLoadImage
                src={product.imageUrl}
                alt={product.name}
                className="product-image"
                effect="blur"
              />
              <div>
                <p className="product-name">{product.name}</p>
                <p className="product-thc">{product.thcPercentage}% THC</p>
                <p className="product-price">${product.price}</p>
              </div>
              <button onClick={(e) => {
                e.stopPropagation(); // Stop event from bubbling up
                handleAddToCart(product, e);
              }} className="add-to-cart-button">
                <FontAwesomeIcon icon={faShoppingCart} />
              </button>
            </div>
          ))}
         </div>
      </div>
      <GoToTop />
    </div>
  );
};

export default Dispensary;
