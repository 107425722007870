import React, { createContext, useState, useEffect } from 'react';

export const OrderContext = createContext();

export const OrderProvider = ({ children }) => {
  const [orderId, setOrderId] = useState(localStorage.getItem('orderId') || null);

  useEffect(() => {
    if (orderId) {
      localStorage.setItem('orderId', orderId);
    } else {
      localStorage.removeItem('orderId');
    }
  }, [orderId]);

  return (
    <OrderContext.Provider value={{ orderId, setOrderId }}>
      {children}
    </OrderContext.Provider>
  );
};
